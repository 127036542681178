import React, { useEffect, useMemo } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { LayoutFactory, PageLayoutType } from '../../layout/LayoutFactory';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { authTokenState, roleAccessState } from '../../../atoms/auth/auth.atom';
import { routes } from '../../../config/routes/routes';
import LazyLoad from './LazyLoading/LazyLoading';
import NotFoundPage from '../../pages/shared/error/NotFoundPage/NotFoundPage';
import { useMutation } from '@tanstack/react-query';
import axios, { SuccessResponse } from '../../../libraries/axios/axios';
import { MappedRoleAccess } from '../../../domain/Role';
import { DefaultError } from '@tanstack/query-core/build/modern/index';
import { apiRoutes } from '../../../config/api/apiRoutes';

/* eslint-disable new-cap */
const Dashboard = LazyLoad(
  () => import('../../pages/shared/admin/DashboardPage/DashboardPage'),
);
const UsersPage = LazyLoad(
  () => import('../../pages/shared/admin/User/UsersPage/UsersPage'),
);
const UserCreatePage = LazyLoad(
  () => import('../../pages/shared/admin/User/UserCreatePage/UserCreatePage'),
);
const UserEditPage = LazyLoad(
  () => import('../../pages/shared/admin/User/UserEditPage/UserEditPage'),
);
const TranslationsPage = LazyLoad(
  () =>
    import(
      '../../pages/shared/admin/Translation/TranslationsPage/TranslationsPage'
    ),
);
const ProfilePage = LazyLoad(
  () => import('../../pages/shared/admin/Profile/ProfilePage/ProfilePage'),
);
const SettingsPage = LazyLoad(
  () => import('../../pages/shared/admin/Settings/SettingsPage/SettingsPage'),
);

const LoginPage = LazyLoad(
  () => import('../../pages/shared/public/LoginPage/LoginPage'),
);
const PasswordResetPage = LazyLoad(
  () => import('../../pages/shared/public/PasswordResetPage/PasswordResetPage'),
);
const PasswordResetConfirmationPage = LazyLoad(
  () =>
    import(
      '../../pages/shared/public/PasswordResetConfirmationPage/PasswordResetConfirmationPage'
    ),
);
const RegistrationConfirmationPage = LazyLoad(
  () =>
    import(
      '../../pages/shared/public/RegistrationConfirmationPage/RegistrationConfirmationPage'
    ),
);
const DeletionConfirmationPage = LazyLoad(
  () =>
    import(
      '../../pages/shared/public/DeletionConfirmationPage/DeletionConfirmationPage'
    ),
);

const CategoriesPage = LazyLoad(
  () =>
    import('../../pages/custom/admin/Category/CategoriesPage/CategoriesPage'),
);
const CategoryEditPage = LazyLoad(
  () =>
    import(
      '../../pages/custom/admin/Category/CategoryEditPage/CategoryEditPage'
    ),
);

const BrandsPage = LazyLoad(
  () => import('../../pages/custom/admin/Brand/BrandsPage/BrandsPage'),
);

const ManufacturersPage = LazyLoad(
  () =>
    import(
      '../../pages/custom/admin/Manufacturer/ManufacturersPage/ManufacturersPage'
    ),
);
const ManufacturerEditPage = LazyLoad(
  () =>
    import(
      '../../pages/custom/admin/Manufacturer/ManufacturerEditPage/ManufacturerEditPage'
    ),
);

const VehiclesPage = LazyLoad(
  () => import('../../pages/custom/admin/Vehicle/VehiclesPage/VehiclesPage'),
);

const ProductsPage = LazyLoad(
  () => import('../../pages/custom/admin/Product/ProductsPage/ProductsPage'),
);
/* eslint-enable new-cap */

export const RouteProvider = () => {
  const authStateValue = useRecoilValue(authTokenState);
  const setRoleAccessStateValue = useSetRecoilState(roleAccessState);

  const { mutate: getMappedRoleAccess } = useMutation<
    SuccessResponse<MappedRoleAccess[]>,
    DefaultError
  >({
    mutationFn: () => axios.get(apiRoutes.roles.mappedAccess),
    onSuccess: (response) => {
      setRoleAccessStateValue(response.data);
    },
  });

  useEffect(() => {
    if (authStateValue) {
      getMappedRoleAccess();
    }
  }, [authStateValue]);

  const adminPages = useMemo(() => {
    if (authStateValue) {
      return (
        <>
          <Route path={routes.admin.dashboard} element={<Dashboard />} />
          <Route path={routes.homepage} element={<Dashboard />} />
          <Route path={routes.admin.users.list} element={<UsersPage />} />
          <Route path={routes.admin.users.new} element={<UserCreatePage />} />
          <Route path={routes.admin.users.edit} element={<UserEditPage />} />
          <Route path={routes.admin.profile} element={<ProfilePage />} />
          <Route path={routes.admin.settings} element={<SettingsPage />} />
          <Route
            path={routes.admin.translations.list}
            element={<TranslationsPage />}
          />
          <Route
            path={routes.admin.categories.list}
            element={<CategoriesPage />}
          />
          <Route
            path={routes.admin.categories.edit}
            element={<CategoryEditPage />}
          />
          <Route path={routes.admin.brands.list} element={<BrandsPage />} />
          <Route
            path={routes.admin.manufacturers.list}
            element={<ManufacturersPage />}
          />
          <Route
            path={routes.admin.manufacturers.edit}
            element={<ManufacturerEditPage />}
          />
          <Route path={routes.admin.vehicles.list} element={<VehiclesPage />} />
          <Route path={routes.admin.products.list} element={<ProductsPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </>
      );
    }

    return <></>;
  }, [authStateValue]);

  const authPages = useMemo(() => {
    if (authStateValue) {
      return (
        <>
          <Route
            path={routes.auth.registrationConfirmation}
            element={<RegistrationConfirmationPage />}
          />
          <Route
            path={routes.auth.deletionConfirmation}
            element={<DeletionConfirmationPage />}
          />
        </>
      );
    }

    return (
      <>
        <Route
          path={routes.auth.registrationConfirmation}
          element={<RegistrationConfirmationPage />}
        />
        <Route
          path={routes.auth.deletionConfirmation}
          element={<DeletionConfirmationPage />}
        />
        <Route
          path={routes.auth.passwordReset}
          element={<PasswordResetPage />}
        />
        <Route
          path={routes.auth.passwordResetConfirmation}
          element={<PasswordResetConfirmationPage />}
        />
        <Route path={routes.auth.login} element={<LoginPage />} />
        <Route path={routes.homepage} element={<LoginPage />} />
        <Route path="*" element={<Navigate to={routes.auth.login} replace />} />
      </>
    );
  }, [authStateValue]);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<LayoutFactory variant={PageLayoutType.admin} />}>
          {adminPages}
        </Route>
        <Route element={<LayoutFactory variant={PageLayoutType.auth} />}>
          {authPages}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default RouteProvider;
